import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/Inbox";
import PeopleIcon from "@mui/icons-material/People";
import PaymentIcon from "@mui/icons-material/Payment";
import BonusIcon from "@mui/icons-material/CardGiftcard";
import GiftIcon from "@mui/icons-material/CardGiftcard";
import SalaryIcon from "@mui/icons-material/Money";
import UpdateIcon from "@mui/icons-material/Update";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WalletIcon from "@mui/icons-material/Wallet";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domain } from "../../Components/config";
import Cookies from "js-cookie";
const drawerWidth = 250;

const AdminPanel = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(""); // State to store the logo URL
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const token = Cookies.get("token");
        console.log("Token:", token);

        const response = await axios.get(`${domain}/api/site-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        console.log("Logo URL fetched:", response.data.logoUrl);
        setLogoUrl(response.data.logoUrl); // Correctly set the logo URL
      } catch (error) {
        console.error("Error fetching the logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {[
          {
            text: "Dashboard",
            icon: (
              <DashboardIcon
                sx={{
                  color:
                    location.pathname === "/dashboard" ? "#FFFFFF" : "#0f6518",
                }}
              />
            ),
            link: "/dashboard",
          },
          {
            text: "Wingo",
            icon: (
              <InboxIcon
                sx={{
                  color:
                    location.pathname === "/wingo-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/wingo-admin",
          },
          {
            text: "Illegal Bets",
            icon: (
              <ReportProblemIcon
                sx={{
                  color:
                    location.pathname === "/illegal-bets"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/illegal-bets",
          },
          // { text: 'K3 Admin', icon: <InboxIcon sx={{ color: location.pathname === '/k3-admin' ? '#FFFFFF' : '#0f6518' }} />, link: '/k3-admin' },
          {
            text: "Members",
            icon: (
              <PeopleIcon
                sx={{
                  color:
                    location.pathname === "/members" ? "#FFFFFF" : "#0f6518",
                }}
              />
            ),
            link: "/members",
          },
          {
            text: "Browse Recharge",
            icon: (
              <PaymentIcon
                sx={{
                  color:
                    location.pathname === "/pending-recharge-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/pending-recharge-admin",
          },
          {
            text: "Browse Withdraw",
            icon: (
              <PaymentIcon
                sx={{
                  color:
                    location.pathname === "/withdraw-admin-status"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/withdraw-admin-status",
          },
          {
            text: "VIP Level",
            icon: (
              <ManageAccountsIcon
                sx={{
                  color:
                    location.pathname === "/vip-levels" ? "#FFFFFF" : "#0f6518",
                }}
              />
            ),
            link: "/vip-levels",
          },
          {
            text: "Update",
            icon: (
              <SettingsIcon
                sx={{
                  color:
                    location.pathname === "/settings-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/settings-admin",
          },
          {
            text: "First Deposit Bonus",
            icon: (
              <BonusIcon
                sx={{
                  color:
                    location.pathname === "/bonus-settings"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/bonus-settings",
          },
          // { text: 'Update Salary Bonus', icon: <SalaryIcon sx={{ color: location.pathname === '/playersSalary' ? '#FFFFFF' : '#0f6518' }} />, link: '/playersSalary' },
          {
            text: "Create Salary",
            icon: (
              <SalaryIcon
                sx={{
                  color:
                    location.pathname === "/create-salary"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/create-salary",
          },
          {
            text: "Create Giftcode",
            icon: (
              <GiftIcon
                sx={{
                  color:
                    location.pathname === "/create-coupon"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/create-coupon",
          },
          {
            text: "Notifications",
            icon: (
              <NotificationsIcon
                sx={{
                  color:
                    location.pathname === "/notifications-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/notifications-admin",
          },
          {
            text: "Recharge (Approved)",
            icon: (
              <PaymentIcon
                sx={{
                  color:
                    location.pathname === "/recharge-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/recharge-admin",
          },
          {
            text: "Withdraw (Approved)",
            icon: (
              <PaymentIcon
                sx={{
                  color:
                    location.pathname === "/withdraw-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/withdraw-admin",
          },
          {
            text: "Withdrawl Settings",
            icon: (
              <SettingsIcon
                sx={{
                  color:
                    location.pathname === "/withdrawl-limits"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/withdrawl-limits",
          },
          {
            text: "Wallet Update",
            icon: (
              <WalletIcon
                sx={{
                  color:
                    location.pathname === "/wallet-update"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/wallet-update",
          },
          {
            text: "Update Turn Over",
            icon: (
              <ManageHistoryIcon
                sx={{
                  color:
                    location.pathname === "/update-turn-over"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/update-turn-over",
          },
          {
            text: "Support",
            icon: (
              <HelpIcon
                sx={{
                  color:
                    location.pathname === "/support-admin"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/support-admin",
          },
          // { text: 'Update Images', icon: <UpdateIcon sx={{ color: location.pathname === '/update-images' ? '#FFFFFF' : '#0f6518' }} />, link: '/update-images' },
          {
            text: "Edit Bank Details",
            icon: (
              <BorderColorIcon
                sx={{
                  color:
                    location.pathname === "/edit-user-bank-details"
                      ? "#FFFFFF"
                      : "#0f6518",
                }}
              />
            ),
            link: "/edit-user-bank-details",
          },
          // { text: 'Activity Setting', icon: <UpdateIcon sx={{ color: location.pathname === '/admin/activity-award' ? '#FFFFFF' : '#0f6518' }} />, link: '/admin/activity-award' },
        ].map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.link || "#"}
            style={{
              background:
                location.pathname === item.link ? "#0f6518" : "transparent",
              color: location.pathname === item.link ? "white" : "black",
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  {item.text}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
  const logoUrlWithCacheBuster = `${logoUrl}?t=${new Date().getTime()}`;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "#0f6518",
          color: "black",
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          >
            <Box
              component="img"
              sx={{
                height: 50, // Adjust the height as needed
                marginRight: 1, // Adjust the space between the image and the text
              }}
              alt="Logo"
              // src={logoUrlWithCacheBuster || "assets/images/logo9.png"} // Use cache-busting URL
              src="../../../assets/damanilogo.png"
            />
          </Typography>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            sx={{ color: "white" }}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={handleProfileMenuClose}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          overflowY: "hidden",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#FFFFFF",

              overflowY: "auto", // Enable vertical scrolling
              "&::-webkit-scrollbar": {
                width: "0px", // Hide scrollbar width
                background: "transparent", // Hide scrollbar background
              },
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            md: `calc(100% - ${drawerWidth}px)`,
            sm: `100%`,
            xs: `100%`,
          },
          mt: 3, // Adjust the margin to match the height of the AppBar
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default AdminPanel;
