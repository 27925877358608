import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { DoubleArrow } from "@mui/icons-material";

// Array of real image URLs from Unsplash
const imageUrls = [
  "https://images.unsplash.com/photo-1506744038136-46273834b3fb?w=350&h=300&fit=crop&crop=entropy",
  "https://images.unsplash.com/photo-1469474968028-56623f02e42e?w=350&h=300&fit=crop&crop=entropy",
  "https://images.unsplash.com/photo-1501854140801-50d01698950b?w=350&h=300&fit=crop&crop=entropy",
  "https://images.unsplash.com/photo-1441974231531-c6227db76b6e?w=350&h=300&fit=crop&crop=entropy",
  "https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=350&h=300&fit=crop&crop=entropy",
];

const PuzzleSlider = ({ onPuzzleSolved }) => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [puzzlePieceLeft, setPuzzlePieceLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [puzzleSolved, setPuzzleSolved] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const sliderRef = useRef(null);
  const drawerRef = useRef(null);
  const navigate = useNavigate();

  const puzzlePieceWidth = 80;
  const puzzlePieceHeight = 80;
  const puzzleGapPosition = 250;
  const sliderHeight = 300;
  const sliderWidth = 350;
  const drawerHeight = 40;
  const snapTolerance = 10;

  useEffect(() => {
    if (puzzleSolved) {
      onPuzzleSolved();
    }
  }, [puzzleSolved, onPuzzleSolved]);

  const getRandomImage = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
  }, []);

  useEffect(() => {
    setBackgroundImage(getRandomImage());
  }, [getRandomImage]);

  const startDragging = (e) => {
    setIsDragging(true);
    if (drawerRef.current) {
      drawerRef.current.setPointerCapture(e.pointerId);
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
    if (Math.abs(puzzlePieceLeft - puzzleGapPosition) < snapTolerance) {
      setPuzzlePieceLeft(puzzleGapPosition);
      setPuzzleSolved(true);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/");
      }, 2000);
    }
  };

  const handleDragging = (e) => {
    if (!isDragging) return;

    const drawerRect = drawerRef.current.getBoundingClientRect();
    const newLeft = e.clientX - drawerRect.left - puzzlePieceWidth / 2;

    if (newLeft >= 0 && newLeft <= sliderWidth - puzzlePieceWidth) {
      setPuzzlePieceLeft(newLeft);
    }
  };

  const puzzlePiecePath = `
    M 0,20
    L 20,0
    L 60,0
    L 80,20
    L 80,60
    L 60,80
    L 20,80
    L 0,60
    Z
  `;

  return (
    <div
      style={{
        width: `${sliderWidth}px`,
        margin: "0 auto",
        position: "relative",
      }}
    >
      {/* Image Slider */}
      <div
        ref={sliderRef}
        style={{
          width: `${sliderWidth}px`,
          height: `${sliderHeight}px`,
          position: "relative",
          border: "1px solid #ccc",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}
      >
        {/* Visible gap */}
        <svg
          width={puzzlePieceWidth}
          height={puzzlePieceHeight}
          style={{
            position: "absolute",
            bottom: "30px",
            left: `${puzzleGapPosition}px`,
            zIndex: 1,
          }}
        >
          <path
            d={puzzlePiecePath}
            fill="rgba(0, 0, 0, 0.4)"
            stroke="#fff"
            strokeWidth="2"
          />
        </svg>

        {/* Puzzle piece */}
        <svg
          width={puzzlePieceWidth}
          height={puzzlePieceHeight}
          style={{
            position: "absolute",
            bottom: "30px",
            left: `${puzzlePieceLeft}px`,
            zIndex: 3,
            filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3))",
            transition: isDragging ? "none" : "left 0.2s ease-out",
          }}
        >
          <defs>
            <clipPath id="puzzlePieceClip">
              <path d={puzzlePiecePath} />
            </clipPath>
          </defs>
          <image
            href={backgroundImage}
            width={sliderWidth}
            height={sliderHeight}
            clipPath="url(#puzzlePieceClip)"
            style={{
              transform: `translate(-${puzzleGapPosition}px, -10px)`,
              filter: "brightness(1.2)",
            }}
          />
          <path
            d={puzzlePiecePath}
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            style={{
              filter:
                Math.abs(puzzlePieceLeft - puzzleGapPosition) < snapTolerance
                  ? "drop-shadow(0 0 5px #00ff00)"
                  : "none",
            }}
          />
        </svg>
      </div>

      {/* Draggable Drawer */}
      <div
        ref={drawerRef}
        onPointerDown={startDragging}
        onPointerMove={handleDragging}
        onPointerUp={stopDragging}
        onPointerCancel={stopDragging}
        style={{
          width: `${sliderWidth}px`,
          height: `${drawerHeight}px`,
          position: "relative",
          backgroundColor: "whitesmoke",
          marginTop: "-2px",
          cursor: "pointer",
          touchAction: "none",
          border: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Instructions */}
        <Tooltip title="Drag to slide the puzzle piece" placement="top">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#333",
              fontWeight: "bold",
            }}
          >
            <div>Drag Me</div>
          </div>
        </Tooltip>

        {/* Green Highlight */}
        <div
          style={{
            width: `${puzzlePieceLeft + puzzlePieceWidth / 2}px`,
            height: "100%",
            backgroundColor: "rgba(0, 255, 0, 0.3)",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: 1,
            transition: isDragging ? "none" : "width 0.2s ease-out",
          }}
        />

        {/* Drawer handle */}
        <div
          style={{
            width: `${puzzlePieceWidth}px`,
            height: "100%",
            backgroundColor: "#ffffff",
            position: "absolute",
            left: `${puzzlePieceLeft}px`,
            transition: isDragging ? "none" : "left 0.2s ease-out",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoubleArrow style={{ fontSize: 24, color: "#007bff" }} />
        </div>
      </div>

      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <h2 style={{ margin: "0 0 10px 0", color: "#007bff" }}>
            Congratulations!
          </h2>
          <p style={{ margin: "0", fontSize: "18px" }}>Puzzle Solved!</p>
        </div>
      )}

      {puzzleSolved && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: `${sliderHeight + drawerHeight}px`,
            backgroundColor: "rgba(0, 255, 0, 0.3)",
            zIndex: 4,
          }}
        />
      )}
    </div>
  );
};

export default PuzzleSlider;
