import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Pagination,
  Tabs,
  Tab,
  Box,
  Grid,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";

import { domain } from "../../Components/config"; // Replace with your API domain

const SupportMain = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openReplyDialog, setOpenReplyDialog] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [updatedPriority, setUpdatedPriority] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");
  const ticketsPerPage = 15;

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${domain}/admin/tickets`, {
          withCredentials: true,
        });
        setTickets(response.data);
      } catch (err) {
        setError("Error fetching tickets.");
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const handleEdit = (ticket) => {
    setSelectedTicket(ticket);
    setUpdatedStatus(ticket.status);
    setUpdatedPriority(ticket.priority);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    if (!selectedTicket) return;

    try {
      await axios.put(
        `${domain}/tickets/admin/${selectedTicket._id}`,
        {
          status: updatedStatus,
          priority: updatedPriority,
        },
        { withCredentials: true }
      );
      const response = await axios.get(`${domain}/admin/tickets`, {
        withCredentials: true,
      });
      setTickets(response.data);
      handleCloseEditDialog();
    } catch (error) {
      console.error("Error updating ticket:", error);
      setError("Error updating ticket.");
    }
  };

  const handleReply = (ticket) => {
    setSelectedTicket(ticket);
    setReplyMessage("");
    setOpenReplyDialog(true);
  };

  const handleCloseReplyDialog = () => {
    setOpenReplyDialog(false);
  };

  const handleSendReply = async () => {
    if (!selectedTicket || !replyMessage) return;

    try {
      await axios.post(
        `${domain}/tickets/admin/${selectedTicket._id}/reply`,
        { message: replyMessage },
        { withCredentials: true }
      );
      const response = await axios.get(`${domain}/admin/tickets`, {
        withCredentials: true,
      });
      setTickets(response.data);
      handleCloseReplyDialog();
    } catch (error) {
      console.error("Error sending reply:", error);
      setError("Error sending reply.");
    }
  };

  const handleDelete = async (ticketId) => {
    try {
      await axios.delete(`${domain}/tickets/admin/${ticketId}`, {
        withCredentials: true,
      });
      const response = await axios.get(`${domain}/admin/tickets`, {
        withCredentials: true,
      });
      setTickets(response.data);
    } catch (error) {
      console.error("Error deleting ticket:", error);
      setError("Error deleting ticket.");
    }
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

  const filteredTickets = tickets.filter((ticket) => {
    if (tabValue === 0) return ticket.status === "Open";
    if (tabValue === 1) {
      const statusMatch = !statusFilter || ticket.status === statusFilter;
      const priorityMatch =
        !priorityFilter || ticket.priority === priorityFilter;

      const createdAt = new Date(ticket.createdAt);
      const startDate = new Date(dateFilterStart);
      const endDate = new Date(dateFilterEnd);
      const dateMatch =
        (!dateFilterStart || createdAt >= startDate) &&
        (!dateFilterEnd || createdAt <= endDate);

      return (
        ["In Progress", "Resolved", "Closed"].includes(ticket.status) &&
        statusMatch &&
        priorityMatch &&
        dateMatch
      );
    }
    return false;
  });

  const currentTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );
  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div style={{ minHeight: "85vh" }}>
      <Container>
        <Box mt={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: "#0f6518"}}>
                Ticket Management
              </Typography>

              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                aria-label="Ticket categories"
                variant="fullWidth"
              >
                <Tab label="Open Tickets" />
                <Tab label="Reviewed Tickets" />
              </Tabs>

              {tabValue === 0 && (
                <Box mt={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Subject</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Created Date</TableCell>
                          <TableCell>Updated Date</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentTickets.map((ticket) => (
                          <TableRow key={ticket._id}>
                            <TableCell>{ticket.subject}</TableCell>
                            <TableCell>{ticket.description}</TableCell>
                            <TableCell>{ticket.status}</TableCell>
                            <TableCell>{ticket.priority}</TableCell>
                            <TableCell>
                              {new Date(ticket.createdAt).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {new Date(ticket.updatedAt).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => handleEdit(ticket)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() => handleReply(ticket)}
                              >
                                <ReplyIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => handleDelete(ticket._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => setCurrentPage(page)}
                      color="primary"
                    />
                  </Box>
                </Box>
              )}

              {tabValue === 1 && (
                <Box mt={2}>
                  <Box mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <TextField
                          select
                          fullWidth
                          // label="Status"
                          value={statusFilter}
                          onChange={(e) => setStatusFilter(e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value="">All Statuses</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Resolved">Resolved</option>
                          <option value="Closed">Closed</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          select
                          fullWidth
                          // label="Priority"
                          value={priorityFilter}
                          onChange={(e) => setPriorityFilter(e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value="">All Priorities</option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          type="date"
                          fullWidth
                          label="Start Date"
                          InputLabelProps={{ shrink: true }}
                          value={dateFilterStart}
                          onChange={(e) => setDateFilterStart(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          type="date"
                          fullWidth
                          label="End Date"
                          InputLabelProps={{ shrink: true }}
                          value={dateFilterEnd}
                          onChange={(e) => setDateFilterEnd(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Subject</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Created Date</TableCell>
                          <TableCell>Updated Date</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentTickets.map((ticket) => (
                          <TableRow key={ticket._id}>
                            <TableCell>{ticket.subject}</TableCell>
                            <TableCell>{ticket.description}</TableCell>
                            <TableCell>{ticket.status}</TableCell>
                            <TableCell>{ticket.priority}</TableCell>
                            <TableCell>
                              {new Date(ticket.createdAt).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              {new Date(ticket.updatedAt).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => handleEdit(ticket)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() => handleReply(ticket)}
                              >
                                <ReplyIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => handleDelete(ticket._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box mt={2} display="flex" justifyContent="center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => setCurrentPage(page)}
                      color="primary"
                    />
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Ticket</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Status"
            fullWidth
            value={updatedStatus}
            onChange={(e) => setUpdatedStatus(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Priority"
            fullWidth
            value={updatedPriority}
            onChange={(e) => setUpdatedPriority(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveChanges}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReplyDialog} onClose={handleCloseReplyDialog}>
        <DialogTitle>Reply to Ticket</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Reply Message"
            multiline
            rows={4}
            fullWidth
            value={replyMessage}
            onChange={(e) => setReplyMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReplyDialog}>Cancel</Button>
          <Button onClick={handleSendReply}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SupportMain;