import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";

import { domain } from "../../Components/config"; // Replace with your actual API domain

// Add these styled component definitions
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[8],
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  background: theme.palette.grey[300],
  "& .MuiLinearProgress-bar": {
    background: `linear-gradient(90deg, ${theme.palette.error.light} 0%, ${theme.palette.error.dark} 100%)`,
    opacity: value / 100,
  },
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];

const IllegalBetsMain = () => {
  const [illegalBetData, setIllegalBetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchUID, setSearchUID] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${domain}/betting-activities`, {
        withCredentials: true,
      });

      setIllegalBetData(response.data.illegalBetSummary);
      setError(null);
    } catch (e) {
      console.error("Fetching data failed:", e);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [fetchData]);

  const sortedAndFilteredData = useMemo(() => {
    let filtered = illegalBetData.filter((user) =>
      user.uid.toLowerCase().includes(searchUID.toLowerCase())
    );
    return filtered.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.totalIllegalCount - b.totalIllegalCount;
      } else {
        return b.totalIllegalCount - a.totalIllegalCount;
      }
    });
  }, [illegalBetData, sortOrder, searchUID]);

  const totalIllegalBets = useMemo(
    () => illegalBetData.reduce((sum, row) => sum + row.totalIllegalCount, 0),
    [illegalBetData]
  );

  const mostActiveUser = useMemo(
    () =>
      illegalBetData.reduce(
        (max, row) =>
          row.totalIllegalCount > max.totalIllegalCount ? row : max,
        { uid: "None", totalIllegalCount: 0 }
      ),
    [illegalBetData]
  );

  const chartData = useMemo(() => {
    const gameData = {};
    illegalBetData.forEach((user) => {
      user.illegalBetRounds.forEach((round) => {
        gameData[round.game] = (gameData[round.game] || 0) + round.betCount;
      });
    });
    return Object.entries(gameData).map(([name, value]) => ({ name, value }));
  }, [illegalBetData]);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchUID(event.target.value);
  };

  const UserDetailsDialog = () => (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="user-details-dialog"
    >
      <DialogTitle id="user-details-dialog">
        User Details: {selectedUser?.username}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">UID: {selectedUser?.uid}</Typography>
        <Typography variant="body1">
          Total Illegal Bets: {selectedUser?.totalIllegalCount}
        </Typography>
        <Typography
          variant="h6"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          Illegal Bet Rounds:
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Game</StyledTableCell>
                <StyledTableCell>Period ID</StyledTableCell>
                <StyledTableCell>Bet Count</StyledTableCell>
                <StyledTableCell>Timestamp</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUser?.illegalBetRounds.map((round, index) => (
                <TableRow key={index}>
                  <TableCell>{round.game}</TableCell>
                  <TableCell>{round.periodId}</TableCell>
                  <TableCell>{round.betCount}</TableCell>
                  <TableCell>
                    {new Date(round.timestamp).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 1, sm: 2, md: 3 },
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h4" gutterBottom fontWeight="bold" color="#0f6518">
        Illegal Bets Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard elevation={3}>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Total Illegal Bets
              </Typography>
              <Typography variant="h3" color="error">
                {totalIllegalBets}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard elevation={3}>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Most Active User
              </Typography>
              <Typography variant="h5" color="primary">
                {mostActiveUser.uid}
              </Typography>
              <Typography variant="subtitle1" color="error">
                Illegal Bets: {mostActiveUser.totalIllegalCount}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard elevation={3}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Illegal Bets by Game
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8">
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel id="sort-order-label">Sort Order</InputLabel>
              <Select
                labelId="sort-order-label"
                value={sortOrder}
                onChange={handleSortChange}
                label="Sort Order"
              >
                <MenuItem value="desc">Highest to Lowest</MenuItem>
                <MenuItem value="asc">Lowest to Highest</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Search by UID"
              variant="outlined"
              value={searchUID}
              onChange={handleSearchChange}
            />
          </Box>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="bold"
            color="#0f6518"
          >
            Illegal Bet Summary
          </Typography>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>UID</StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Games</StyledTableCell>
                  <StyledTableCell>Total Illegal Count</StyledTableCell>
                  <StyledTableCell>Last Updated</StyledTableCell>
                  <StyledTableCell>Activity Level</StyledTableCell>
                  <StyledTableCell>Views</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAndFilteredData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.uid}</TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.games}</TableCell>
                    <TableCell>{row.totalIllegalCount}</TableCell>
                    <TableCell>
                      {new Date(row.lastUpdated).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <StyledLinearProgress
                        variant="determinate"
                        value={Math.min(
                          (row.totalIllegalCount / 10) * 100,
                          100
                        )}
                        color="error"
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "#0f6518" }}
                        size="small"
                        onClick={() => handleViewDetails(row)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <UserDetailsDialog />
    </Box>
  );
};

export default IllegalBetsMain;
